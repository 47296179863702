(function(){

	var botaoSofrerAcao = $('#irtopo');

	botaoSofrerAcao.click(function(){

		$('body,html').animate({
			scrollTop: 0
		}, 2000)

	});

})();