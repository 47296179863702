(function(){

	var alvoReceberClasseMobile = $('#toggle-class');

	var gatilhoTrocarClaseMobile = $('.bg-menu, #toggleMenu');

	gatilhoTrocarClaseMobile.click(function(){

		alvoReceberClasseMobile.toggleClass('aberto');

	});

})();