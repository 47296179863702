(function(){

	var popupIncrivel = $('.popup-incrivel');

	var sairPopup = $('.fechar-popup,.popup-incrivel');

	sairPopup.click(function(){
		popupIncrivel.removeClass('apareceu');
	});

})();