function joazin(id, opcoes){
    if($(id).children().length > 1){
        $(id).owlCarousel(opcoes)
    }else{
        $(id).addClass('owl-loaded');
    }
}

joazin('#inovacao-carousel',{
    loop:true,
    autoplay:true,
    nav:true,
    navText:[
        '<span class="icon icon-card-inovacao-left pull-left" title="Item Anterior"></span>',
        '<span class="icon icon-card-inovacao-right pull-right" title="Próximo Item"></span>'
    ],
    responsive:{
        0:{
            items:1
        },
        576:{
            items:1
        },
        768:{
            items:2,
            margin:5
        },
        992:{
            items:3,
            margin:15,
        },
        1200:{
            items:4,
            margin:28,
        }
    }
});

joazin('#atuacao-carousel',{
    loop:true,
    autoplay:true,
    nav:true,

    navText:[
        '<span class="icon icon-card-atuacao-left pull-left" title="Item Anterior"></span>',
        '<span class="icon icon-card-atuacao-right pull-right" title="Próximo Item"></span>'
    ],
    responsive:{
        0:{
            items:1
        },
        576:{
            items:1
        },
        768:{
            items:2,
            margin:20
        },
        992:{
            items:3,
            margin:30
        },
        1200:{
            items:3,
            margin:30
        }
    }
});

joazin('#depoimentos-carousel',{
    loop:true,
    autoplay:true,
    nav:true,

    navText:[
        '<span class="icon icon-card-inovacao-left pull-left" title="Item Anterior"></span>',
        '<span class="icon icon-card-inovacao-right pull-right" title="Próximo Item"></span>'
    ],
    responsive:{
        0:{
            items:1
        },
        576:{
            items:1
        },
        768:{
            items:1,
        },
        992:{
            items:1,
        },
        1200:{
            items:1,
        }
    }
});

joazin('#blog-carousel',{
    loop:true,
    autoplay:true,
    nav:true,

    navText:[
        '<span class="icon icon-card-inovacao-left pull-left" title="Item Anterior"></span>',
        '<span class="icon icon-card-inovacao-right pull-right" title="Próximo Item"></span>'
    ],
    responsive:{
        0:{
            items:1
        },
        576:{
            items:2,
            margin:10
        },
        768:{
            items:3,
            margin:10
        },
        992:{
            items:3,
            margin:12
        },
        1200:{
            items:4,
            margin:15
        }
    }
});

joazin('#internas-sobre-carousel',{
    loop:true,
    autoplay:true,
    responsive:{
        0:{
            items:1
        },
        576:{
            items:1
        },
        768:{
            items:2,
            margin:20
        },
        992:{
            items:3,
            margin:30
        },
        1200:{
            items:4,
            margin:35
        }
    }
});