function ValidaDados(){

	if(document.frEntrar.txtUsuario.value == ""){
		alert("O campo usuário é obrigatório");
		return false;
	}
	if(document.frEntrar.txtSenha.value == ""){
		alert("O campo senha é obrigatório");
		return false;
	}
	
	return true;
}